module.exports = {
  palette: {
    main: "#444444",
    secondary: "#ff4747",
    light: "#eeeeee",
    dark: "#111111",
  },
  typography: {
    fontFamily: "Karla",
  },
}
